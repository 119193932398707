import React from "react";

import {gql, useQuery} from "@apollo/client";
import BienRowListado from "./ListadoBienesRow.js";

const LISTADO_BIENES = gql`
    query BienesListado {
        bien(limit: 20) {
            id
            titulo
            descripcion
            localidad
            subasta {
                link
                cantidad_reclamada
            }
        }
    }


`;


const ListadoBienes = () => {

    const { loading, error, data } = useQuery(LISTADO_BIENES);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    return (
        <section className="antialiased bg-gray-100 text-gray-600 h-screen px-4">
            <div className="flex flex-col justify-center h-full">
                <div className="w-full max-w-2xl mx-auto bg-white shadow-lg rounded-sm border border-gray-200">
                    <header className="px-5 py-4 border-b border-gray-100">
                        <h2 className="font-semibold text-gray-800">Últimos inmuebles</h2>
                    </header>
                    <div className="p-3">
                        <div className="overflow-x-auto">
                            <table className="table-auto w-full">
                                <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
                                <tr>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-left">Localidad</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-left">Titulo</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-left">Descripción</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-center">Cantidad reclamada</div>
                                    </th>
                                    <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-center">Detalles</div>
                                    </th>
                                </tr>
                                </thead>
                                <tbody className="text-sm divide-y divide-gray-100">
                                {data.bien && data.bien.map((x, i) =>
                                    <BienRowListado key={i} titulo={x.titulo} descripcion={x.descripcion} localidad={x.localidad} link={x.subasta.link} cantidad_reclamada={x.subasta.cantidad_reclamada} />
                                )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default ListadoBienes;