import React from "react";

class BienRowListado extends React.Component {

    render() {
        return (
            <tr>
                <td className="p-2 whitespace-nowrap">
                    <div className="text-left">{this.props.localidad}</div>
                </td>
                <td className="p-2 whitespace-nowrap">
                    <div className="text-left">{this.props.titulo}</div>
                </td>
                <td className="p-2 whitespace-nowrap">
                    <div className="text-left">{this.props.descripcion}</div>
                </td>
                <td className="p-2 whitespace-nowrap">
                    <div className="text-left font-medium text-green-500">{this.props.cantidad_reclamada} €</div>
                </td>
                <td className="p-2 whitespace-nowrap">
                    <div className="text-lg text-center"><a href={this.props.id} /> </div>
                </td>
            </tr>
        )
    }
}

export default BienRowListado;
