import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/index.css';
import {ApolloClient, InMemoryCache, ApolloProvider,} from "@apollo/client";

import App from './App';


const client = new ApolloClient({
    uri: process.env.HASURA_URL,
    cache: new InMemoryCache()
});

ReactDOM.render(
    <ApolloProvider client={client}>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </ApolloProvider>,
    document.getElementById('root')
);
