import ListadoBienes from "./components/ListadoBienes";

function App() {
  return (
      <main className="bg-gray-100 dark:bg-gray-800 rounded-2xl h-screen overflow-hidden relative">
        <ListadoBienes />
      </main>
  );
}

export default App;
